import { Link } from "@remix-run/react";
import { Icons } from "~/components/Icons";
import { Flex, Heading, Button } from "@radix-ui/themes";
import logo from "~/images/vendr-backoffice-logo.webp";
import { segmentTrackClick } from "~/lib/segment-utils";
import { ElementType } from "~/services/segment-types";

export default function AuthenticationPage() {
  return (
    <Flex
      flexGrow="1"
      direction="column"
      align="center"
      justify="center"
      mt="8"
    >
      <img
        style={{ height: "400px", maxHeight: "400px" }}
        src={logo}
        alt="Vendr Backoffice Logo"
      />
      <Heading
        style={{ fontSize: "6em", lineHeight: "1em", marginBottom: "0.5em" }}
      >
        Backoffice
      </Heading>
      <Button variant="soft" size="4" asChild>
        <Link
          to="/auth"
          onClick={() =>
            segmentTrackClick("Login with OKTA", ElementType.Link, "Login page")
          }
        >
          Login With
          <Icons.oktaWords height="1.3em" />
        </Link>
      </Button>
    </Flex>
  );
}
